import {useState, useEffect, createContext, type ReactNode} from "react";
import {
  getZoomFromLocalStorage,
  setZoomToLocalStorage,
  getServerUserSettingsFromLocalStorage,
  setServerUserSettingsToLocalStorage,
} from "common/utils/userSettings";

import {updateUserSettings} from "../api/services/UserSettings";
import {isEducational} from "common/constants/core";
import {getDefaultTheme, logError} from "common/utils/common";
import {
  EUserSettings,
  EZoomRange,
  EColorScheme,
  ETheme,
} from "common/constants/enums";
import {DEFAULT_COLOR_SCHEME} from "common/constants/constants";
import {useAuthContext} from "common/hooks/useAuthContext";

interface IUserSettingsProvider {
  children: ReactNode;
}

interface IUserSettingsContext {
  zoomMain: number;
  zoomFavourite: number;
  setDefaultUserSettings: () => void;
  onChangeTheme: () => void;
  theme: ETheme;
  onUpZoom: (key: EUserSettings) => void;
  onDownZoom: (key: EUserSettings) => void;
}

const UserSettingsContext = createContext<IUserSettingsContext | null>(null);
export const UserSettingsContextProvider = ({
  children,
}: IUserSettingsProvider) => {
  const {user, setServerUserSettings, serverUserSettings} = useAuthContext();
  const [zoomMain, setZoomMain] = useState<number>(
    getZoomFromLocalStorage(EUserSettings.ZOOM_MAIN),
  );
  const [zoomFavourite, setZoomFavourite] = useState<number>(
    getZoomFromLocalStorage(EUserSettings.ZOOM_FAVOURITE),
  );
  const [theme, setTheme] = useState<ETheme>(getDefaultTheme);

  useEffect(() => {
    user && setZoomToLocalStorage(EUserSettings.ZOOM_MAIN, zoomMain);
  }, [zoomMain, user]);
  useEffect(() => {
    user && setZoomToLocalStorage(EUserSettings.ZOOM_FAVOURITE, zoomFavourite);
  }, [zoomFavourite, user]);

  const onUpZoom = (key: EUserSettings) => {
    switch (key) {
      case EUserSettings.ZOOM_MAIN: {
        zoomMain < EZoomRange.ZOOM_MAX && setZoomMain(prev => prev + 1);
        break;
      }
      case EUserSettings.ZOOM_FAVOURITE: {
        zoomFavourite < EZoomRange.ZOOM_MAX &&
          setZoomFavourite(prev => prev + 1);
        break;
      }
    }
  };

  const onDownZoom = (key: EUserSettings) => {
    switch (key) {
      case EUserSettings.ZOOM_MAIN: {
        zoomMain > EZoomRange.ZOOM_MIN && setZoomMain(prev => prev - 1);
        break;
      }
      case EUserSettings.ZOOM_FAVOURITE: {
        zoomFavourite > EZoomRange.ZOOM_MIN &&
          setZoomFavourite(prev => prev - 1);
      }
    }
  };

  useEffect(() => {
    const htmlColorScheme = serverUserSettings?.theme
      ? serverUserSettings.theme
      : DEFAULT_COLOR_SCHEME;
    const htmlClass = isEducational ? "educational" : "corporate";
    const rootElement = document.getElementById("root") as Element;
    const html = document.getElementsByTagName("html")[0];
    html?.setAttribute("class", htmlClass);
    !isEducational && html?.setAttribute("color-scheme", htmlColorScheme);
    rootElement.className = theme;
  }, [theme]);

  useEffect(() => {
    !isEducational && setTheme(getThemeFromColorScheme());
  }, [serverUserSettings]);

  const toggleColorScheme = () =>
    serverUserSettings?.theme === EColorScheme.LIGHT
      ? EColorScheme.DARK
      : EColorScheme.LIGHT;

  const getThemeFromColorScheme = () =>
    serverUserSettings?.theme === EColorScheme.DARK
      ? ETheme.DARK_CORPORATE_THEME
      : ETheme.LIGHT_CORPORATE_THEME;

  const onChangeTheme = () => {
    const newColorScheme = toggleColorScheme();
    updateUserSettings({
      theme: newColorScheme,
    }).catch(logError);
    setServerUserSettingsToLocalStorage({
      ...getServerUserSettingsFromLocalStorage(),
      theme: newColorScheme,
    });
    setServerUserSettings(prev => ({
      ...prev,
      theme: newColorScheme,
    }));
  };

  const setDefaultUserSettings = () => {
    setZoomMain(EZoomRange.ZOOM_MIN);
    setZoomFavourite(EZoomRange.ZOOM_MIN);
  };

  return (
    <UserSettingsContext.Provider
      value={{
        zoomMain,
        zoomFavourite,
        onUpZoom,
        onDownZoom,
        setDefaultUserSettings,
        onChangeTheme,
        theme,
      }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsContext;
