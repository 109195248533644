import {MessageTemplatePage} from "../Template/MessageTemplatePage";

export function Wip() {
  return (
    <MessageTemplatePage
      code={404}
      headerText="Еще не готово"
      messageText="Страница, которую вы искали, пока находится в разработке"
    />
  );
}
