import {MessageTemplate} from "components/Service/MessageTemplate";
import {useNavigate} from "react-router-dom";
import {ERoute} from "apps/sliderary/configs/ERoute";
import styles from "./Service.module.less";

interface IProps {
  onClick?: () => void;
  headerText: string;
  messageText: string;
  code?: number | string;
}

export function MessageTemplatePage({
  onClick,
  headerText,
  messageText,
  code,
}: IProps) {
  const navigate = useNavigate();
  return (
    <main className={styles.wip}>
      <MessageTemplate
        code={code}
        headerText={headerText}
        messageText={messageText}
        onClick={onClick ?? (() => navigate(ERoute.MAIN))}
      />
    </main>
  );
}
