import {KEYS_TO_SAVE_IN_STORAGE} from "../constants/constants";
import {ELocalStorageKeys} from "../constants/enums";

export function removeKeysFromLocalStorageByList() {
  let key: keyof typeof ELocalStorageKeys;
  for (key in ELocalStorageKeys) {
    const value = ELocalStorageKeys[key];
    if (!KEYS_TO_SAVE_IN_STORAGE.some(keyToSave => keyToSave === value)) {
      window.localStorage.removeItem(value);
    }
  }
}

export function getFromStorage(
  key: ELocalStorageKeys,
  defaultValue: unknown = null,
) {
  const result = window.localStorage.getItem(key);
  return result ? JSON.parse(result) : defaultValue;
}

export function setToStorage(key: ELocalStorageKeys, value: unknown) {
  window.localStorage.setItem(key, JSON.stringify(value));
}
