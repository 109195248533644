import {LazyButton} from "components/Shared/Button";
import {Suspense} from "react";
import {isEducational} from "common/constants/core";
import styles from "./MessageTemplage.module.less";

interface IMessageTemplateProps {
  onClick: () => void;
  headerText: string;
  messageText: string;
  code?: number | string;
}

export function MessageTemplate({
  onClick,
  headerText,
  messageText,
  code,
}: IMessageTemplateProps) {
  return (
    <section className={styles.container}>
      <p className={styles.code}>{code ?? null}</p>
      <div>
        <p className={styles.header}>{headerText}</p>
        <p className={styles.text}>{messageText}</p>
      </div>
      <Suspense fallback={<div className={styles.buttonSkeleton}></div>}>
        <LazyButton
          type="fill"
          size={isEducational ? "l" : "m"}
          onClick={onClick}>
          Вернуться на главную
        </LazyButton>
      </Suspense>
    </section>
  );
}
