import {type Components, type Paths} from "../../../types/client";
import client from "../../client";
import {objectToUrlString} from "../../../common/utils/http";
import {getTabs} from "../MainWindow";
import {type AxiosResponse} from "axios";
import {logError} from "common/utils/common";

enum EFavouritesWindow {
  BATCH = "favourites/batch",
}

export const BATCH_COUNT = 10;

export async function getFavouritePageLoader() {
  return {
    tabsLoader: await getTabs(),
    favouritesBatchLoader: await getFavouritesBatch({count: BATCH_COUNT}),
  };
}

export async function getFavouritesBatch(
  params: Paths.ApiFavouritesBatch.Get.QueryParameters,
): Promise<AxiosResponse<Components.Schemas.FWBatch, any> | null> {
  try {
    const queryParameters = objectToUrlString(params);

    return await client.get(`${EFavouritesWindow.BATCH}?${queryParameters}`);
  } catch (error) {
    logError(error);
    return null;
  }
}
