export enum ERoute {
  MAIN = "/",
  SIGN_IN = "/signIn",
  CATEGORIES = "/categories/:id",
  PROFILE = "/profile",
  PROFILE_PERSONAL = "/profile/personal",
  PROFILE_SECURITY = "/profile/security",
  PROFILE_RATING = "/profile/rating",
  UPLOADED = "/uploaded",
  FAVOURITE = "/favourite",
  ADMINISTRATION = "/administration",
  ADMINISTRATION_METADATA = "/administration/metadata",
  ADMINISTRATION_CONTENT = "/administration/content",
  ADMINISTRATION_USERS = "/administration/users",
  ADMINISTRATION_ANALYTICS = "/administration/analytics",
  SERVICE_WIP = "/service/wip",
}
