import axios from "axios";
import {type MouseEvent} from "react";
import {ETheme, EZoomRange} from "../constants/enums";
import {isEducational} from "../constants/core";

/** Проверяет произошел ли клик внутри компонента
 *
 * @param {MouseEvent} e Событие клика.
 * @param {HTMLElement} element Элемент, который необходимо проверить.
 */
export function checkIsClickInside(e: MouseEvent, element: HTMLElement) {
  const r = element.getBoundingClientRect();

  return (
    e.clientX > r.left &&
    e.clientX < r.right &&
    e.clientY > r.top &&
    e.clientY < r.bottom
  );
}

export function getKiloBytesFromMegaBytes(value: number) {
  return value * 1024;
}

export function getKiloBytesFromBytes(value: number) {
  return value / 1024;
}

export function logError(error: unknown) {
  if (axios.isCancel(error)) {
    console.log("Request canceled", error?.message);
  } else {
    const result = error instanceof Error ? error.message : String(error);

    console.log("ERROR", result);
  }
}

export const getValidZoomValue = (zoom?: number | null): number => {
  const newZoom = Number(zoom);
  const isValidZoom =
    !!newZoom &&
    newZoom <= EZoomRange.ZOOM_MAX &&
    newZoom >= EZoomRange.ZOOM_MIN;

  return isValidZoom ? newZoom : EZoomRange.ZOOM_MIN;
};

export const getDefaultTheme = () =>
  isEducational ? ETheme.EDUCATIONAL_THEME : ETheme.LIGHT_CORPORATE_THEME;

export function noop() {}
