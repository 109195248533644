import {type AxiosResponse, type AxiosRequestConfig} from "axios";
import client from "../../client";
import {type Components, type Paths} from "../../../types/client";
import {logError} from "common/utils/common";
import {isCorporate} from "../../../common/constants/core";

export enum EAuthUrl {
  LOGIN = "/auth/login",
  REFRESH = "auth/refresh",
  SSO_ALLOW = "/auth/sso/allow",
  SSO_SIGNIN = "/auth/sso/signin",
  SSO_COMPLETE = "/auth/sso/complete",
}

export async function getSignInPageLoader() {
  return {
    authSsoAllowLoader: isCorporate ? await authSSOAllow() : null,
  };
}

export const authLogin = async (
  body: Components.Schemas.Credentials,
): Promise<AxiosResponse<Components.Schemas.AuthenticationInfo>> => {
  return await client.post(EAuthUrl.LOGIN, body);
};

export const authRefresh = async (
  params: Paths.ApiAuthRefresh.Get.Parameters.Token,
): Promise<AxiosResponse<Components.Schemas.AuthenticationInfo>> => {
  return await client.get(EAuthUrl.REFRESH, {
    params,
  });
};

export const authSSOAllow =
  async (): Promise<AxiosResponse<boolean> | null> => {
    try {
      return await client.get(EAuthUrl.SSO_ALLOW);
    } catch (error) {
      logError(error);
      return null;
    }
  };

export const authSSOComplete = async (
  params: AxiosRequestConfig,
): Promise<AxiosResponse<Components.Schemas.AuthenticationInfo>> => {
  return await client.get(EAuthUrl.SSO_COMPLETE, params);
};
