import client from "api/client";
import {type AxiosResponse} from "axios";
import {type Components} from "../../../types/client";
import {logError} from "common/utils/common";

enum EMainWindowUrl {
  TABS = "main/tabs",
}

export async function getTabs(): Promise<AxiosResponse<
  Components.Schemas.MWTab[],
  any
> | null> {
  try {
    return await client.get(EMainWindowUrl.TABS);
  } catch (error) {
    logError(error);
    return null;
  }
}
