import {
  type ReactNode,
  createContext,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from "react";
import jwt_decode from "jwt-decode";
import {
  authLogin,
  authRefresh,
  authSSOComplete,
} from "../api/services/Auth/Auth";
import {type Components} from "types/client";
import {
  clearPermissions,
  getPermissionsFromStorage,
  savePermissionsToStorage,
} from "common/utils/auth";
import {ECommonEvents, ELocalStorageKeys} from "common/constants/enums";
import {removeKeysFromLocalStorageByList} from "common/utils/localStorage";
import {
  getServerUserSettingsFromLocalStorage,
  preparedUserSettings,
  setServerUserSettingsToLocalStorage,
} from "common/utils/userSettings";
import {type AxiosResponse} from "axios";

interface AuthProvider {
  children: ReactNode;
}

interface IAuthContext {
  user: unknown | null;
  login: (body: any) => Promise<void>;
  refresh: (params: any) => Promise<void>;
  logout: () => void;
  permissions: Components.Schemas.Permissions | null;
  serverUserSettings: Components.Schemas.UserSettings | null;
  setServerUserSettings: Dispatch<
    SetStateAction<Components.Schemas.UserSettings | null>
  >;
  loginWithSSO: () => void;
}

const AuthContext = createContext<IAuthContext | null>(null);
export const AuthContextProvider = ({children}: AuthProvider) => {
  const [user, setUser] = useState(() => {
    try {
      const accessToken = localStorage.getItem(ELocalStorageKeys.ACCESS_TOKEN);

      if (accessToken !== null) {
        return jwt_decode(accessToken);
      }

      return null;
    } catch (error) {
      return null;
    }
  });

  const [permissions, setPermissions] =
    useState<Components.Schemas.Permissions | null>(getPermissionsFromStorage);
  const [serverUserSettings, setServerUserSettings] =
    useState<Components.Schemas.UserSettings | null>(
      getServerUserSettingsFromLocalStorage,
    );

  useEffect(() => {
    const resetPermissions = (e: any) => {
      const result =
        (
          e as {
            permissions: Components.Schemas.Permissions;
          }
        ).permissions ?? null;

      setPermissions(state => {
        if (!state) {
          return result;
        } else {
          return result ? {...state, ...result} : result;
        }
      });
    };
    document.addEventListener(
      ECommonEvents.RESET_PERMISSIONS,
      resetPermissions,
    );

    return () => {
      document.removeEventListener(
        ECommonEvents.RESET_PERMISSIONS,
        resetPermissions,
      );
    };
  }, []);

  const handleSetPermissions = (
    permissions: Components.Schemas.Permissions,
  ) => {
    savePermissionsToStorage(permissions);

    setPermissions(permissions);
  };

  const login = async (body: any) => {
    const response = await authLogin(body);
    setLoginData(response);
  };

  const refresh = async (params: any) => {
    try {
      const response = await authRefresh(params);
      setLoginData(response);
    } catch (e) {
      localStorage.removeItem(ELocalStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem("refreshToken");
      clearPermissions();

      setUser(null);
      setPermissions(null);
    }
  };

  const logout = () => {
    removeKeysFromLocalStorageByList();
    clearPermissions();

    setUser(null);
    setPermissions(null);
  };

  const handleSetUserSettings = (
    userSettings: Components.Schemas.UserSettings,
  ) => {
    const prepared = preparedUserSettings(userSettings);
    setServerUserSettingsToLocalStorage(prepared);
    setServerUserSettings(prepared);
  };

  const loginWithSSO = async () => {
    const response = await authSSOComplete({
      withCredentials: true,
    });
    setLoginData(response);
  };

  const setLoginData = (
    response: AxiosResponse<Components.Schemas.AuthenticationInfo, any>,
  ) => {
    if (response.data.accessToken && response.data.refreshToken) {
      window.localStorage.setItem(
        ELocalStorageKeys.ACCESS_TOKEN,
        response.data.accessToken,
      );
      window.localStorage.setItem(
        ELocalStorageKeys.REFRESH_TOKEN,
        response.data.refreshToken,
      );

      setUser(jwt_decode(response.data.accessToken));
    }

    if (response.data.accessToken && response.data.permissions) {
      handleSetPermissions(response.data.permissions);
    }

    if (response.data.accessToken && response.data.userSettings) {
      handleSetUserSettings(response.data.userSettings);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        refresh,
        logout,
        permissions,
        serverUserSettings,
        setServerUserSettings,
        loginWithSSO,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
