import {getValidZoomValue} from "./common";
import {EColorScheme, EUserSettings, EZoomRange} from "../constants/enums";
import type {Components} from "../../types/client";
import {DEFAULT_COLOR_SCHEME, serverUserSettings} from "../constants/constants";

export const getZoomFromLocalStorage = (key: string) => {
  switch (key) {
    case EUserSettings.ZOOM_FAVOURITE:
    case EUserSettings.ZOOM_MAIN: {
      return getValidZoomValue(Number(localStorage.getItem(key)));
    }
    default:
      return EZoomRange.ZOOM_MIN;
  }
};

export const setZoomToLocalStorage = (key: string, value?: number | null) => {
  key && localStorage.setItem(key, getValidZoomValue(value).toString());
};

export const preparedUserSettings = (
  userSettings: Components.Schemas.UserSettings,
) => {
  const result: Record<string, any> = {};
  const colorSchemes = Object.values(EColorScheme);
  Object.values(serverUserSettings).forEach(value => {
    const fieldName = EUserSettings[value as keyof typeof EUserSettings];
    const fieldValue = (userSettings as any)[fieldName];

    result[fieldName] =
      fieldName === EUserSettings.THEME
        ? colorSchemes.includes(fieldValue as EColorScheme)
          ? fieldValue
          : DEFAULT_COLOR_SCHEME
        : fieldValue;
  });
  return result as Components.Schemas.UserSettings;
};

export const setServerUserSettingsToLocalStorage = (
  userSettings: Components.Schemas.UserSettings,
) => {
  Object.keys(userSettings).forEach(fieldName => {
    const fieldValue = userSettings[fieldName as keyof typeof userSettings];
    localStorage.setItem(fieldName, fieldValue?.toString() ?? "");
  });
};

export const getServerUserSettingsFromLocalStorage = () => {
  const result: Record<string, string | null> = {};
  Object.values(serverUserSettings).forEach(value => {
    const fieldName = EUserSettings[value as keyof typeof EUserSettings];
    result[fieldName] = localStorage.getItem(fieldName);
  });
  return result as Components.Schemas.UserSettings;
};
