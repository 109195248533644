import type {GenericAbortSignal} from "axios";
import client from "api/client";
import {type Paths} from "types/client";

enum EUserSettingsUrl {
  UPDATE_USER_SETTINGS = "/user-settings",
}

export async function updateUserSettings(
  body: Paths.ApiUserSettings.Patch.RequestBody,
  signal?: GenericAbortSignal,
) {
  return await client.patch(EUserSettingsUrl.UPDATE_USER_SETTINGS, body, {
    signal,
  });
}
