import {useRouteError} from "react-router-dom";
import {MessageTemplatePage} from "../Template/MessageTemplatePage";
import {getCombinedError} from "common/utils/errors";

export function Error() {
  const error = useRouteError();
  const {status, statusText, data, error: errorRaw} = getCombinedError(error);
  const statusTextMessage =
    statusText && /not found/gi.test(statusText)
      ? "Страница не найдена"
      : statusText;
  const message =
    status === 404
      ? "Страница, которую вы искали, была перемещена или больше недоступна."
      : data ?? errorRaw?.message;

  return (
    <MessageTemplatePage
      code={status ?? 404}
      /* eslint-disable */
      headerText={statusTextMessage || "Ошибка"}
      /* eslint-enable */
      messageText={message ?? "Что-то пошло не так"}
    />
  );
}
