import React from "react";
import ReactDOM from "react-dom/client";
import {App} from "./apps/sliderary";
import {isCorporate} from "common/constants/core";

window.SL_VERSION = SL_VERSION;

if (process.env.NODE_ENV === "development") {
  window.ARGO_ENV = ARGO_ENV;
  window.FEATURE_FLAGS = FEATURE_FLAGS;
}

const element = document.getElementById("root") as Element;

const root = ReactDOM.createRoot(element);

element.className = `${isCorporate ? "light-corporate" : "educational"}-theme`;

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
