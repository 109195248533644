import {Component, type JSX, Suspense} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {ruRU} from "@mui/x-date-pickers/locales";
import "dayjs/locale/ru";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AuthContextProvider} from "../../provider/AuthProvider";
import Routes from "./routes";
import "assets/less/styles.less";
import {StorageContextProvider} from "../../provider/StorageProvider";
import {UserSettingsContextProvider} from "../../provider/UserSettingsProvider";

export class App extends Component {
  render(): JSX.Element {
    return (
      <AuthContextProvider>
        <StorageContextProvider>
          <UserSettingsContextProvider>
            <LocalizationProvider
              adapterLocale="ru"
              localeText={
                ruRU.components.MuiLocalizationProvider.defaultProps.localeText
              }
              dateAdapter={AdapterDayjs}>
              <Suspense>
                <Routes />
              </Suspense>
            </LocalizationProvider>
          </UserSettingsContextProvider>
        </StorageContextProvider>
      </AuthContextProvider>
    );
  }
}
