import {PERMISSION_FIELD_MAP} from "common/constants/constants";
import {type Components} from "types/client";

export function clearPermissions() {
  Object.values(PERMISSION_FIELD_MAP).forEach(permission => {
    window.localStorage.removeItem(permission.toString());
  });
}

export function savePermissionsToStorage(
  permissions: Components.Schemas.Permissions,
) {
  Object.keys(permissions).forEach((fieldName: string) => {
    const key = (PERMISSION_FIELD_MAP as any)[fieldName];
    if (typeof key === "number") {
      window.localStorage.setItem(
        key.toString(),
        `${(permissions as any)[fieldName] ? 1 : 0}`,
      );
    }
  });
}

export function getPermissionsFromStorage() {
  const result: Record<string, boolean> = {};

  Object.keys(PERMISSION_FIELD_MAP).forEach(fieldName => {
    const itemValue = window.localStorage.getItem(
      (PERMISSION_FIELD_MAP as any)[fieldName].toString(),
    );

    if (itemValue) {
      result[fieldName] = Boolean(+itemValue);
    }
  });

  return result;
}
