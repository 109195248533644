import axios, {AxiosError} from "axios";
import {EAuthUrl} from "./services/Auth/Auth";
import {savePermissionsToStorage} from "common/utils/auth";
import {ECommonEvents, ELocalStorageKeys} from "common/constants/enums";
import {ERoute} from "apps/sliderary/configs/ERoute";

export const BASE_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + "/api"
  : "/api";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, accessToken: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(accessToken);
    }
  });

  failedQueue = [];
};

const client = axios.create({
  baseURL: BASE_URL,
});

client.interceptors.request.use(config => {
  const accessToken = localStorage.getItem(ELocalStorageKeys.ACCESS_TOKEN);

  client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      originalRequest.url !== EAuthUrl.LOGIN &&
      !originalRequest._retry &&
      error.response &&
      error.response.status === 401
    ) {
      if (isRefreshing) {
        return await new Promise(function (resolve, reject) {
          failedQueue.push({resolve, reject});
        })
          .then(async accessToken => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return await client(originalRequest);
          })
          .catch(async err => {
            return await Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = window.localStorage.getItem(
        ELocalStorageKeys.REFRESH_TOKEN,
      );

      return await new Promise(function (resolve, reject) {
        !refreshToken
          ? (function (pageRoute: string) {
              localStorage.removeItem(ELocalStorageKeys.ACCESS_TOKEN); // Если придет 401 и в localStorage нет refreshToken, но есть accessToken, то будет дудос на страницу логина.
              reject(new Error("Can not be refreshed"));
              window.location.assign(`${window.location.origin}${pageRoute}`); // Если localStorage был очищен от переполнения или юхер намеренно почистил токены, то редирект на страницу логина
            })(ERoute.SIGN_IN)
          : client
              .get(EAuthUrl.REFRESH, {params: {token: refreshToken}})
              .then(({data}) => {
                window.localStorage.setItem(
                  ELocalStorageKeys.ACCESS_TOKEN,
                  data.accessToken,
                );
                window.localStorage.setItem(
                  ELocalStorageKeys.REFRESH_TOKEN,
                  data.refreshToken,
                );

                data.permissions && savePermissionsToStorage(data.permissions);
                const event = new Event(ECommonEvents.RESET_PERMISSIONS) as any;
                event.permissions = data.permissions;
                document?.dispatchEvent(event);

                client.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
                originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
                processQueue(null, data.accessToken);
                resolve(client(originalRequest));
              })
              .catch(err => {
                const tokenExpiredRegExp =
                  /refresh.token.(expired|not found)/gis;
                processQueue(err, null);
                reject(err);
                if (
                  err instanceof AxiosError &&
                  (err.status === 400 || err.status === 404) &&
                  err.response &&
                  err.response.data?.title &&
                  tokenExpiredRegExp.test(err.response.data.title)
                ) {
                  localStorage.removeItem(ELocalStorageKeys.REFRESH_TOKEN);
                  localStorage.removeItem(ELocalStorageKeys.ACCESS_TOKEN);
                  window.location.assign(
                    `${window.location.origin}${ERoute.SIGN_IN}`,
                  );
                }
              })
              .finally(() => {
                isRefreshing = false;
              });
      });
    }

    return await Promise.reject(error);
  },
);

export default client;
